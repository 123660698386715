<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
           <el-link type="primary" style="line-height: 32px" @click="dialogExport=true">查询数据导出</el-link>
          </div>
        </ayl-table>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        nav: [{name: "业务报表", path: ""}, {name: "地点报表", path: ""}],
        table: {
          api: vm.$api.customerDestination,
          query: {
            startTime: null,
            endTime: null,
            orderType: null,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
              {
                type: 'dateSection',
                title: '日期',
                date: [],
                modelName: ['startTime', 'endTime'],
            },
            {
                type: 'select',
                title: '业务类型',
                model: 'orderType',
                placeholder: '请选择',
                width: '150px',
                option: this.$enums.OrderNewType.list,
            },
            {
              type: 'input',
              model: 'queryContent',
              placeholder: '目的地'
            }],
          columns: [
            //   {
            //   title: '序号',
            //   width: '50px',
            //   $index: 'index',
            // },
            {
              title: '目的地',
              width: '100px',
              key: 'destination',
              fixed: "left",
              showTooltip: true,
            }, {
              title: '车次',
              width: '100px',
              fixed: "left",
              key: 'transportNum',
            }, {
              title: '重量(吨)',
              width: '90px',
              fixed: "left",
              key: 'weight',
            }, {
              title: '里程',
              width: '100px',
              fixed: "left",
              key: 'mileage',
            }, {
              title: '客户数',
              width: '100px',
              fixed: "left",
              key: 'partnerNum',
            }, {
              title: '收入',
              key: 'income',
              showTooltip: true,
            }, {
              title: '成本',
              key: 'cost',
              showTooltip: true,
            }, {
              title: '毛利',
              key: 'grossProfit',
              showTooltip: true,
            }, {
              title: '平均每车次收入',
              key: 'averageIncome',
              width: '150px',
              showTooltip: true,
            }, {
              title: '平均每车次毛利',
              key: 'averageGrossProfit',
              width: '150px',
              showTooltip: true,
            }, {
              title: '操作',
              width: '100px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '订单明细')
                ])
              }
            }]
        }
      }
    },
    methods: {
      // 导出
      async exportExcel(){
        this.btnLoading = true
        try {
          const url = await this.$api.reportExcel({
            params: {
              startTime: this.table.query.startTime,
              endTime: this.table.query.endTime,
              orderType: this.table.query.orderType,
              queryContent: this.table.query.queryContent,
            },
            
            excelKey: 'DestinationOrderReport',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        // eslint-disable-next-line no-empty
        }catch (e) {}
        this.btnLoading = false
      },
      //编辑
      goEdit(val){
        this.$router.push({
          path: '/business-report/order-details',
          query: {
            startTime: this.table.query.startTime,
            endTime: this.table.query.endTime,
            orderType: this.table.query.orderType,
            queryContent: val.destination,
            type: 'site',
            queryType: 4,
          }
        })
      },
    },
    async mounted () {
      // await this.$search(this.table)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "DestinationOrderReport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    }
  }
</script>

<style lang='sass' scoped>

</style>
